<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" class="info-block" color="white">
    <v-row>
      <v-col cols="12" md="6">
        <v-img class="mb-2" heigth="40" max-width="184" src="@/assets/img/logo-long.svg"></v-img>
        <v-btn class="my-2" to="/sign-up" text color="gray"><v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}</v-btn>
        <div class="f30 font-weight-bold mb-2">{{ $t('setting.info') }}</div>
        <div class="gray--text mb-5">{{ $t('setting.infoDesc') }}</div>
      </v-col>
    </v-row>
    <v-form @submit.prevent="action" lazy-validation>
      <v-row>
        <v-col cols="12" md="6" class="pb-0 pt-md-3 pt-0">
          <div class="font-weight-medium">{{ $t('input.firstNameLabel') }}</div>
          <v-text-field
            class="field56"
            v-model="firstName"
            :error-messages="firstNameErrors"
            :placeholder="$t('input.namePla')"
            outlined
            required
            color="primary"
            height="56"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pb-0 pt-md-3 pt-0 mt-md-0 mt-n2">
          <div class="font-weight-medium">{{ $t('input.lastNameLabel') }}</div>
          <v-text-field
            class="field56"
            v-model="lastName"
            :error-messages="lastNameErrors"
            :placeholder="$t('input.namePla')"
            outlined
            required
            color="primary"
            height="56"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pb-0 pt-md-3 pt-0 mt-md-0 mt-n2">
          <div class="font-weight-medium">{{ $t('input.birthdayLabel') }}</div>
          <v-menu v-model="modal" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="field56"
                v-model="birthday"
                :error-messages="dateErrors"
                :placeholder="$t('input.birthdayPla')"
                outlined
                readonly
                required
                color="primary"
                height="56"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <img width="24" height="24" src="@/assets/icon/calendar.svg" />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :max="new Date(time).toISOString().substr(0, 10)"
              :show-current="new Date(time).toISOString().substr(0, 10)"
              color="primary"
              v-model="birthday"
              @input="modal = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="pb-0 pt-md-3 pt-0 mt-md-0 mt-n2">
          <div class="font-weight-medium">{{ $t('input.emailLabel') }}</div>
          <v-text-field
            class="field56"
            v-model="email"
            :error-messages="emailErrors"
            :placeholder="$t('input.emailPla')"
            outlined
            required
            color="primary"
            height="56"
          >
            <template v-slot:prepend-inner>
              <img width="24" height="24" src="@/assets/icon/mail.svg" />
            </template>
          </v-text-field>
          <div class="text-md-right text-center mt-md-7">
            <v-btn depressed type="submit" max-width="392" width="100%" height="64" class="primary">{{ $t('btn.next') }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      firstName: '',
      lastName: '',
      birthday: null,
      email: '',
      error: [],
    };
  },
  mounted() {
    if (this.profile) {
      this.profile.birthday == null ? '' : (this.birthday = this.profile.birthday);
      this.profile.last_name == null ? '' : (this.lastName = this.profile.last_name);
      this.profile.first_name == null ? '' : (this.firstName = this.profile.first_name);
      this.profile.email == null ? '' : (this.email = this.profile.email);
    }
  },
  methods: {
    async action() {
      this.error = [];
      const formData = new FormData();
      formData.append('first_name', this.firstName);
      formData.append('last_name', this.lastName);
      formData.append('birthday', this.birthday);
      formData.append('email', this.email);
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          // this.$router.push('/risk');
          this.$router.push('/document');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    time() {
      return `${new Date().getFullYear() - 18}-${new Date().getMonth() + 1}-${new Date().getDate() + 1}`;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push(this.$t('inputError.firstNameRequired'));
      this.error.find((item) => item == 'first_name__invalid') && errors.push(this.$t('inputError.firstNameInvalid'));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push(this.$t('inputError.lastNameRequired'));
      this.error.find((item) => item == 'last_name__invalid') && errors.push(this.$t('inputError.lastNameInvalid'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push(this.$t('inputError.emailRequired'));
      this.error.find((item) => item == 'email__invalid') && errors.push(this.$t('inputError.emailInvalid'));
      this.error.find((item) => item == 'email__exists') && errors.push(this.$t('inputError.emailExists'));
      this.error.find((item) => item == 'email__update_too_often') && errors.push(this.$t('inputError.emailTooOften'));
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == 'birthday__required') && errors.push(this.$t('inputError.birthdayRequired'));
      this.error.find((item) => item == 'birthday__invalid') && errors.push(this.$t('inputError.birthdayInvalid'));
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .info-block {
  padding: 30px 94px 94px;
  @media screen and (max-width: 959px) {
    padding: 36px 28px;
  }
  @media screen and (max-width: 599px) {
    padding: 36px 16px;
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh;
  }
}
</style>
